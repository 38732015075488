<template>
    <div>
        <headertop/>
        <div>
            <img style="width:100%" src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/图.png" alt="">

        </div>
        <div class="zhizhao">
            <div class="jieshao1">
                <b>平台资质</b>
                <p>PLATFORM INTRODUCTION</p>
                 <span
          style="
            width: 100px;
            display: block;
            margin: 0 auto;
            margin-top: 10px;
            height: 4px;
            background: #4272c3;
          "
        ></span>
            </div>
      <div class="zhaizhaoimg">
          <ul>
              <li><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/许可证.png" alt="">
              <p>道路运输经营许可证</p>
              </li>
              <li><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/许可证1.png" alt="">
              <p>道路运输经营许可证</p>
              </li>
          </ul>
            </div>
        </div>
        <div class="map">
             <div class="jieshao1">
                <b>联系我们</b>
                <p>CONTACT US</p>
                 <span
          style="
            width: 100px;
            display: block;
            margin: 0 auto;
            margin-top: 10px;
            height: 4px;
            background: #4272c3;
          "
        ></span>
            </div>
            <div class="mapimg">
              <ul>
                  <li><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/map.png" alt=""></li>
                  <li>
                      <p style="margin-top:48px">公司名称：陕西泽诚供应链管理有限公司</p>
                      <p>公司电话：177-0292-6351</p>
                      <p><span style="float:left">公司地址：</span><span style="float:left">陕西省西安市未央区经开区凤城八路136号保亿隆基中<br />心B座1201室</span></p>
                  </li>
            </ul>  
            </div>
        </div>
        <bottombar/>
    </div>
</template>
<script>
import headertop from '@/components/headertop.vue'
import bottombar from '@/components/bottombar.vue'
export default {
    components: {
    headertop,
    bottombar
  },
}
</script>
<style scoped>
.zhizhao{
        height: 800px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
}
.jieshao1 {
        width: 1200px;
        height: 100px;
        position: absolute;
        left: 0;
        right: 0;
        top: 102px;
        margin: 0 auto;
        text-align: center;
    }

    .jieshao1 b {
        font-size: 39px;
    }
    .jieshao1 p{
        color: #CCCCCC;
        font-size: 20px;
        margin-top: 10px;
    }
    .zhaizhaoimg{
        width: 1200px;
        height: 340px;
        margin-top: 120px;
    }
    .zhaizhaoimg ul{
        width: 1200px;
        height: 380px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .zhaizhaoimg ul li{
        position: relative;
        width: 488px;
        height: 380px;
        text-align: center;
    }
    .zhaizhaoimg ul li img:hover{
        height: 360px;
        width: 508px;
    }
    .zhaizhaoimg ul li p{
        position: absolute;
    top: 338px ;
    left: 140px;
        font-size: 24px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #333333;
margin-top: 30px;
    }
    .map{
        height: 800px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background:url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/摄图网.png)no-repeat 0px 386px;
}
.mapimg{
    height: 585px;
    width: 1244px;
    margin-top: 212px;
     display: flex;
        justify-content: space-around;
        align-items: center;
}
.mapimg ul{
     height: 585px;
    width: 1244px;
        display: flex;
        justify-content: space-between;
        align-items: center;
}
.mapimg li img:hover{
    width: 605px;
    height: 370px;
}
.mapimg ul li{
width: 585px;
height: 350px;
}
.mapimg ul li p{
 font-size: 16px;
font-family: PingFang SC;
font-weight: 400;
color: #000000;
line-height: 36px;
}
</style>