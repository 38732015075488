<template>
  <div class="topheader">
    <div class="header">
      <div class="logo"></div>
     
  <el-menu :default-active="$route.path"
          router class="el-menu-demo" active-text-color="#4272C3"  mode="horizontal" @select="handleSelect">
   <el-menu-item index="/">首页</el-menu-item>
  <el-menu-item index="/Information">信息公告</el-menu-item>
  <el-menu-item index="/resources">资源信息</el-menu-item>
  <el-menu-item index="/car">车源信息</el-menu-item>
  <el-menu-item index="/our">联系我们</el-menu-item>
  <el-menu-item><a href="http://huoyun.zechengsk.com" target="_blank" >控制台</a></el-menu-item>
  
</el-menu>
    </div>
  </div>
</template>

<script>
export default {
   data() {
      return {
        
      };
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      }
    }
};
</script>
<style scoped>
.topheader{
  width: 100%;
  height: 67px;
  background-color: white;
   position: fixed;
   z-index: 999999;
}
.header {
  margin:0 auto;
  height: 67px;
  line-height: 67px;
  width: 1200px;
}
.el-menu.el-menu--horizontal {
    border-bottom:none;
}
.header ul {
  /* margin-left: 462px; */
  margin-left: 273px;
}
.el-menu-item{
  font-size: 18px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #333333;
}
.logo {
  float: left;
  width: 220px;
  height: 52px;
  margin-top: 14px;
  background: url(http://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/zchy-logo.png) no-repeat 100%;
    background-size: 100%;
}

.header ul li {
  float: left;
  height: 55px;
  margin-left: 20px;
  margin-top: 5px;
  text-align: center;
}
.header ul li {
  color: #333333;
  font-size: 18px;
}
.appBox{
  display: flex;
}
.appBox .appImg{
  display: inline-block;
  position: relative;
  width: 131px;
  height: 41px;
  background: url(../assets/img/app.png) no-repeat;
  background-size: 100% 100%;
  margin: 15px 0 0 55px;
  cursor: pointer;
  }
.appCard{
  display: none;
  position: absolute;
  bottom: -215px;
  left: -34px;
  width: 203px;
  height: 212px;
  background: url(../assets/img/appCardBox.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.appCard img{
  width: 110px;
  height: 110px;
  margin-top: 18px;
}
.appBox .appImg:hover .appCard{
  display: block;
}
</style>
<style >
li.el-menu-item:hover{
   color: #4272C3 !important; 
   border-bottom: 4px solid rgb(66, 114, 195) !important;
   border-radius: 2px;
}
li.is-active{
  color: #4272C3 !important; 
   border-bottom: 4px solid rgb(66, 114, 195) !important;
   border-radius: 2px;
}
</style>
